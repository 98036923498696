<template>
  <div class="userDetails-box">
    <div class="box-title">
      <span class="title-one">回执单 / </span>
      <span class="title-two">详情</span>
      <el-button class="back-btn" size="mini" @click="onBack">返回</el-button>
    </div>
    <div class="box-userBasicInfo">
      <p class="title">
        回执单信息
        <el-button
          type="text"
          @click="onEditUserInfo"
          class="edit-btn">
          编辑
          <i class="el-icon-edit-outline"></i>
        </el-button>
      </p>
      <div class="userBasicInfo">
        <div class="receiptDescriptions">
          <el-descriptions :column="4" >
            <el-descriptions-item label="品牌型号">{{receiptInfo.brandName}}</el-descriptions-item>
            <el-descriptions-item label="设备序列号">{{receiptInfo.deviceSn}}</el-descriptions-item>
            <el-descriptions-item label="患者姓名">{{receiptInfo.patientName}}</el-descriptions-item>
            <el-descriptions-item label="植入时间">
              {{moment.unix(receiptInfo.implantDate / 1000).format('Y-MM-DD')}}</el-descriptions-item>
            <el-descriptions-item label="身份证号">{{receiptInfo.patientIdNumber}}</el-descriptions-item>
            <el-descriptions-item label="联系手机号">{{receiptInfo.patientPhone}}</el-descriptions-item>
            <el-descriptions-item label="植入医院">{{receiptInfo.hospitalName}}</el-descriptions-item>
            <el-descriptions-item label="医院术者">{{receiptInfo.operator}}</el-descriptions-item>
            <el-descriptions-item label="患者病因">{{receiptInfo.etiology}}</el-descriptions-item>
            <el-descriptions-item label="患者住址">{{receiptInfo.addressDetail}}</el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="receiptImg">
          <el-image
            style="width: 172px; height: 107px"
            :src="receiptInfo.imagePath"
            :preview-src-list="[receiptInfo.imagePath]">
          </el-image>
        </div>
      </div>
    </div>
    <div class="box-patientInfo">
      <p class="title">
        修改记录
      </p>
      <el-table
      :data="operateLogList">
        <el-table-column
          align="center"
          fixed
          label="序号"
          type="index"
          width="50">
        </el-table-column>
        <el-table-column
          align="center"
          label="修改内容">
          <template slot-scope="scope">
            <span>{{scope.row.description}}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="修改人">
          <template slot-scope="scope">
            <span>{{scope.row.operatorName}}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="修改日期">
          <template slot-scope="scope">
            <span v-if="scope.row.operateTime">{{moment.unix(scope.row.operateTime / 1000).format('Y-MM-DD')}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <EditReceipt
      :visible.sync="editReceiptDrawer"
      @editReceiptSuccess1="editReceiptSuccess1"
    ></EditReceipt>
  </div>
</template>
<script>
import { getModifyList, getDeviceReceiptDetail } from '@/serve/module/receipt'
import EditReceipt from './editReceipt.vue'
const moment = require('moment')
export default {
  components: {
    EditReceipt
  },
  data () {
    return {
      moment: moment,
      editReceiptDrawer: false,
      receiptInfo: {},
      operateLogList: []
    }
  },
  mounted () {
    this.getDeviceReceiptDetail(this.$route.query.receiptId)
    this.getModifyList(this.$route.query.receiptId)
  },
  methods: {
    getDeviceReceiptDetail (receiptId) {
      getDeviceReceiptDetail(receiptId).then(res => {
        if (res) {
          this.receiptInfo = res.data
          this.receiptInfo.receiptId = this.$route.query.receiptId
        }
      })
    },
    getModifyList (id) {
      getModifyList(id).then(res => {
        if (res.data) {
          this.operateLogList = res.data.operateLogs
        }
      })
    },
    onEditUserInfo () {
      this.editReceiptDrawer = true
    },
    onBack () {
      this.$router.push({ name: 'receiptManage' })
    },
    editReceiptSuccess1 () {
      window.location.reload()
    }
  }
}
</script>
<style scoped lang="scss">
.userDetails-box {
  margin: 0 24px;
  .box-title {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 14px;
    .title-one {
      color: #909399;
    }
    .title-two {
      color: #131414;
    }
    .back-btn {
      position: absolute;
      right: 20px;
    }
  }
  .box-userBasicInfo,.box-patientInfo,.box-familyInfo {
    margin-bottom: 24px;
    .title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #0F1114;
      margin-bottom: 12px;
    }
    .userBasicInfo {
      display: flex;
      align-content: space-between;
      .receiptDescriptions {
        display: inline-block;
        // width: 90%;
      }
    }
  }
}

.edit-btn {
  margin-left: 10px;
}

.my-label {
  color: red;
  background: #E1F3D8;
}

</style>
